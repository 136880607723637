import * as React from 'react';
import Helmet from 'react-helmet';
import holdingImage from '../images/sam-fischer-holding.jpg';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => {
  return (
    <Layout pageTitle='A Setlist Generator'>
      <Seo title='Sam Fischer - Setlist Generator' />
      <Helmet>
        <script type='text/javascript' src='//sme.theappreciationengine.com/framework/js/497?segment=979167'></script>
        <script defer type='text/javascript' src='./scripts/aeLoginHandler.js'></script>
      </Helmet>
      <div className='[ b-holding ]'>
        <Link to='/track-listing/' className='[ b-holding__link ]'>
          <img src={holdingImage} alt='SamFischer' className='[ b-holding__image ]' />
          <button className='[ u-button ][ b-holding__button ]'>Tap to Begin</button>
        </Link>
      </div>
    </Layout>
  );
};

export default IndexPage;
